import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/home-landing-header-style.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import InHubVideo from '../../../assets/videos/InHub_blueprint.mp4'

const HomeLandingHeader = () => {
  const {t} = useTranslation();

  return (
    <>
      <StaticImage
        className="circle-motive-left"
        src="../../../images/home/theme-circles/circle-elements-left.png"
        alt="Circle motive left"
        placeholder="none"
        loading="lazy"
      />

      <div className="intro-ctn">
        <div className="container">
          <div className="intro-header-ctn">
            <h1>{t`home.about-title`}</h1>
            <p>{t`home.about-paragraph`}</p>
          </div>

          <div className="intro-image-ctn">

            {/*<video loop muted autoPlay playsInline poster="#" className="video-background" id="video-home">*/}
            {/*  <source src={InHubVideo} type="video/mp4" />*/}
            {/*</video>*/}

            <div className="video-background yt-video">
              <div className="video-wrapper">
                <iframe
                  width='100%'
                  src="https://www.youtube.com/embed/gSLUTJtAiyk?si=kYJOpCMuV1Lk1RtJ&autoplay=1&mute=1&controls=0&loop=1&playlist=gSLUTJtAiyk&modestbranding=1&showinfo=0&rel=0&playsinline=1&enablejsapi=1&iv_load_policy=3"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            </div>
            <div className="hero-gradient "/>



            {/*<div className="video-background">*/}
            {/*  <div className="video-wrapper">*/}
            {/*    <iframe*/}
            {/*      src="https://www.youtube.com/embed/gSLUTJtAiyk?controls=0&autoplay=1&modestbranding=1&showinfo=0"*/}
            {/*      frameBorder="0"*/}
            {/*      allow="autoplay; encrypted-media"*/}
            {/*      allowFullScreen*/}
            {/*      title="YouTube video"*/}
            {/*    ></iframe>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="container we-work-with-ctn">
        <h3>{t`home.our-support`}</h3>
        <div>
          <a href="https://uprp.gov.pl/pl" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/uprp.png"
              alt="Logo Urzędu Patentowego Rzeczypospolitej Polskiej"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.uw.edu.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/z5.svg"
              alt="Logo Uniwersytetu Warszawskiego"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.parp.gov.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/parp.png"
              alt="Logo Polskiej Agencji Rozwoju Przedsiębiorczości"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.sgh.edu.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/z1.svg"
              alt="Logo Szkoły Głównej Handlowej"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.gpw.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/gpw.png"
              alt="Logo Giełdy Papierów Wartościowych"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.agh.edu.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/z2.svg"
              alt="Logo Akademi Hutniczo Górniczej"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.gov.pl/web/ncbr" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/ncbir.png"
              alt="Logo Narodowego Instytutu Badań i Rozwoju"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.sggw.edu.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/z6.svg"
              alt="Logo SGGW"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
          <a href="https://www.pw.edu.pl/" target="_blank" rel="noreferrer nofollow">
            <StaticImage
              src="../../../images/home/we-work-with-logos/z8.svg"
              alt="Logo Politechnika Warszawska"
              placeholder="dominantColor"
              loading="lazy"
            />
          </a>
        </div>
      </div>

    </>
  );
}

export default HomeLandingHeader;
